import React, {Component} from 'react';
import {Link , Redirect} from 'react-router-dom';
import $ from 'jquery';
import {api_url} from '../Config';
import Header from './Header';
import background from '../assets/images/770.gif';
import Footer from './Footer';
import ConsigneeHeader from '../component/ConsigneeHeader';
class PickupRequest extends Component {
constructor(){
super();
this.state = {
redirectToReferrer: false,
base_url:api_url.base_url,
allData:[],
new_track:'',
loading:false,
// orderData:this.props.location.state.data,
};
this.gotoTrackDetails = this.gotoTrackDetails.bind(this);
this.searchRecords = this.searchRecords.bind(this);
this.logout = this.logout.bind(this);
}
componentDidUpdate()
{

 this.state.allData.map((indexs,index)=>{
    $(`#table_btn_menu_${index}`).click(function(){
      $(`.main_wrapper_table_${index}`).toggle();
    });

    $(`#table_btn_menus_${index}`).click(function(){
      $(`.main_wrapper_tables_${index}`).toggle();
    });
  })
}
componentDidMount(){
  // localStorage.removeItem('consignee_id');

  this.state.allData.map((index,item)=>{
    $(`#table_btn_menu_${index}`).click(function(){
      $(`.main_wrapper_table_${index}`).toggle();
    });
  })




        let trackData = {
            consignee_id:localStorage.getItem('consignee_id')
        }
      const addUrl = this.state.base_url+'consignee_api.php?type=track_history';
        fetch(addUrl,
        {

            method: 'POST',
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify(trackData)
        })
        .then((response) => response.json()
            .then((result) => {
               this.setState({
                 allData:result.data
               })
        }))
        .catch((error) => {

            });





}
logout(){
  localStorage.removeItem('consignee_id');
  this.setState({redirectToReferrer:true})
}
gotoTrackDetails(track_no)
{
  localStorage.setItem('get_track',track_no);
  this.setState({
    new_track:true
  })
}
componentWillUnmount(){
  localStorage.removeItem('data');
}
componentWillMount() {
 if(localStorage.getItem("consignee_id")){
     this.getUserData();
 }
 else{
     this.setState({redirectToReferrer: true});
 }
}
getUserData() {
 JSON.parse(localStorage.getItem("consignee_id"));
}
searchRecords(e)
{
  this.setState({loading:true});
  let trackData = {
            track_no:e.target.value,
            consignee_id:localStorage.getItem('consignee_id')
        }
      const addUrl = this.state.base_url+'consignee_api.php?type=track_history_single';
        fetch(addUrl,
        {

            method: 'POST',
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify(trackData)
        })
        .then((response) => response.json()
            .then((result) => {
               this.setState({
                 allData:result.data,
                 loading:false
               })
        }))
        .catch((error) => {
              this.setState({

                 loading:false
               })
            });
}
render(){
   const lang =JSON.parse(localStorage.getItem("lang"));
  const {loading} = this.state;
const data = JSON.parse(localStorage.getItem("get_track"));
  if(this.state.new_track)
    {
      return(<Redirect to='track-detail' />)
    }
    if(this.state.redirectToReferrer)
    {
      return(<Redirect to='/' />)
    }
// const {allData} = this.state
return(
<React.Fragment>
<body id="page-name">
  <div className="main_body register_main track_shipment_page" id="desktop_view">
    <div className="login_screen">
      <ConsigneeHeader title="Tracking Listing" />
    <div className="track_shipments pb-2">
      <div className="form_box get_quote   ">
        <input type="search" placeholder="Search" onChange={this.searchRecords} />
        <svg className="search_box" viewBox="0 0 24 24"><path d="M9.5 4a6.5 6.5 0 0 1 4.932 10.734l5.644 5.644l-.707.707l-5.645-5.645A6.5 6.5 0 1 1 9.5 4zm0 1a5.5 5.5 0 1 0 0 11a5.5 5.5 0 0 0 0-11z" fill="#626262"/></svg>
      </div>
    </div>
    <div className="main_table_fix delivery_table_">
      <table className="resposive_table">
      {loading ?
        <div className="form_loader">
                 <img src={background} alt="" />
            </div>
        :
        <tbody>
          { this.state.allData.length>0 ? this.state.allData.map((item,index)=>{
            return(


              <tr key={index}>
                <td  className="deliver_icon" data-label={item.track_no}><svg viewBox="0 0 24 24" ><path d="M6 5h2.5a3 3 0 1 1 6 0H17a3 3 0 0 1 3 3v11a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3zm0 1a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-1v3H7V6H6zm2 2h7V6H8v2zm3.5-5a2 2 0 0 0-2 2h4a2 2 0 0 0-2-2zm5.65 8.6L10 18.75l-3.2-3.2l.707-.707L10 17.336l6.442-6.443l.707.707z" fill="#626262"/></svg> {item.created_at}  </td>
                <td className="deliver_icon" data-label=" Shipper detail">
                   <svg ><path d="M5.5 14a2.5 2.5 0 0 1 2.45 2H15V6H4a2 2 0 0 0-2 2v8h1.05a2.5 2.5 0 0 1 2.45-2zm0 5a2.5 2.5 0 0 1-2.45-2H1V8a3 3 0 0 1 3-3h11a1 1 0 0 1 1 1v2h3l3 3.981V17h-2.05a2.5 2.5 0 0 1-4.9 0h-7.1a2.5 2.5 0 0 1-2.45 2zm0-4a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zm12-1a2.5 2.5 0 0 1 2.45 2H21v-3.684L20.762 12H16v2.5a2.49 2.49 0 0 1 1.5-.5zm0 1a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zM16 9v2h4.009L18.5 9H16z" fill="#626262"></path></svg>

                 <i className="fa fa-bars" id={`table_btn_menu_${index}`}></i>
                  <div className={`main_wrapper_table_${index}`} style={{display:'none'}}>
                    <ul className="fix_ul_li">
                      <li><b> {lang.pickup_city}<span className="float-right"> :</span></b><span>{item.origin}</span></li>
                      <li><b>{lang.account_name} <span className="float-right"> :</span></b> <span>{item.sname}</span></li>
                      <li><b>{lang.bussiness_name} <span className="float-right"> :</span></b> <span>{item.sbname}</span></li>
                      <li><b>{lang.email} <span className="float-right"> :</span></b> <span>{item.semail}</span></li>

                      <li><b>{lang.address} <span className="float-right"> :</span></b> <span>{item.sender_address}</span></li>
                    </ul>
                  </div>
                </td>
                <td className="deliver_icon" data-label=" Delivery detail">
                  <svg viewBox="0 0 24 24"><path d="M6 5h2.5a3 3 0 1 1 6 0H17a3 3 0 0 1 3 3v11a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3zm0 1a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-1v3H7V6H6zm2 2h7V6H8v2zm3.5-5a2 2 0 0 0-2 2h4a2 2 0 0 0-2-2zm5.65 8.6L10 18.75l-3.2-3.2l.707-.707L10 17.336l6.442-6.443l.707.707z" fill="#626262"/></svg>

                 <i className="fa fa-bars" id={`table_btn_menus_${index}`}></i>
                  <div className={`main_wrapper_tables_${index}`} style={{display:'none'}}>
                    <ul className="fix_ul_li">
                      <li><b> {lang.pickup_city} <span className="float-right"> :</span></b><span>{item.destination}</span></li>
                      <li><b>{lang.account_name} <span className="float-right"> :</span></b> <span>{item.rname}</span></li>
                      <li><b>{lang.phone} # <span className="float-right"> :</span></b> <span>{item.rphone}</span></li>
                      <li><b>{lang.email} <span className="float-right"> :</span></b> <span>{item.remail}</span></li>
                      <li><b>{lang.address} <span className="float-right"> :</span></b> <span>{item.receiver_address}</span></li>
                    </ul>
                  </div>
                </td>
                <td data-label="COD">
                  <div className="delivery_icon_list cod_list">
                    <svg viewBox="0 0 24 24"><path d="M5.5 14a2.5 2.5 0 0 1 2.45 2H15V6H4a2 2 0 0 0-2 2v8h1.05a2.5 2.5 0 0 1 2.45-2zm0 5a2.5 2.5 0 0 1-2.45-2H1V8a3 3 0 0 1 3-3h11a1 1 0 0 1 1 1v2h3l3 3.981V17h-2.05a2.5 2.5 0 0 1-4.9 0h-7.1a2.5 2.5 0 0 1-2.45 2zm0-4a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zm12-1a2.5 2.5 0 0 1 2.45 2H21v-3.684L20.762 12H16v2.5a2.49 2.49 0 0 1 1.5-.5zm0 1a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zM16 9v2h4.009L18.5 9H16z" fill="#626262"></path></svg>
                  </div>
                {item.collection_amount}</td>
                <td  className="Pending_approval" data-label="">
                {
                <Link to={`/tracking-detail/${item.track_no}`} className="track_btn">{lang.tracking}</Link>
                }
                <h6 style={{color:'#f27474'}}  className="parcel_received">{item.status}</h6></td>

              </tr>



              )


          }):"No Record found..."}






        </tbody>
      }
      </table>
    </div>
  </div>
</div>
<Footer />

</body>
</React.Fragment>
)
}
}
export default PickupRequest;
