import React, {Component} from 'react';
// import {Redirect} from 'react-router-dom';
import {Link , Redirect} from 'react-router-dom';
import $ from 'jquery';
import {api_url} from '../Config';
import Header from './Header';
import swal from 'sweetalert';
import Footer from './Footer';
import CommonHeader from '../component/CommonHeader';
class Home extends Component {
  constructor(){
    super();
    this.state = {
        redirectToReferrer: false,
        email:'',
        password:'',
        base_url:api_url.base_url,
    };
    this.save_record = this.save_record.bind(this);
    this.signup = this.signup.bind(this);
    this.onChange = this.onChange.bind(this);
}
componentDidMount(){


}
// componentWillMount() {
//   if(sessionStorage.getItem("userData")){
//     this.getUserData();
//   }
//   else{
//     this.setState({redirectToReferrer: true});
//   }
// }
// getUserData() {
//   JSON.parse(sessionStorage.getItem("userData"));

// }
save_record(consignee_id)
{
  let addData = {
        track_no:localStorage.getItem('track_no'),
        'consignee_id':consignee_id
    }
    const addUrl = this.state.base_url+'consignee_api.php?type=save_record';
    fetch(addUrl,
    {

        method: 'POST',
        headers:
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body:JSON.stringify(addData)
    })
    .then((response) => response.json()
        .then((result) => {
            if(result.response===1){
              sessionStorage.setItem("consignee_id",1);

            }
    }))
    .catch((error) => {

        });
}

signup()
{
  if(this.state.email ===''|| this.state.password==='')
  {
    alert('Email and Password required.')
  }else{
            let signupData = {
            email:this.state.email,
            'password':this.state.password
        }
        const addUrl = this.state.base_url+'consignee_api.php?type=cosignee_signup';
        fetch(addUrl,
        {

            method: 'POST',
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify(signupData)
        })
        .then((response) => response.json()
            .then((result) => {
              if (result.response===2) {
                swal(result.message)
              }else{
                  localStorage.setItem('consignee_id',result.consignee_id);
                  this.setState({
                redirectToReferrer:true,
              })
              }

        }))
        .catch((error) => {

            });
  }


}
onChange(e){
   this.setState({[e.target.name]:e.target.value});
}
render(){
   const lang =JSON.parse(localStorage.getItem("lang"));
  if(this.state.redirectToReferrer)
  {
    return(<Redirect to='/' />)
  }
return(

<React.Fragment>
  <body id="page-name">
<CommonHeader />
<div className="main_body body_img_bg">
<div className="login_screen fix_screen" id="register_screen" style={{padding: '63px 0 0 !important'}}>
    <div className="row">
      <div className="col-lg-12 fix_logo_f ">
        <h3>{lang.lets_start}</h3>
        <p>{lang.plz_enter_requested_info}</p>
      </div>
    </div>
    <div className="track_shipments" id="login_desktop">
        <div className="form_box get_quote">
          <input type="email" placeholder="Username or Email Adress "  onChange={this.onChange} name="email"/>
          <svg style={{top: '7px'}} className="search_box" viewBox="0 0 24 24"><path d="M21 9v9a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V9c0-1.11.603-2.08 1.5-2.598l-.003-.004l8.001-4.62l8.007 4.623l-.001.003A2.999 2.999 0 0 1 21 9zM3.717 7.466L11.5 12.52l7.783-5.054l-7.785-4.533l-7.781 4.533zm7.783 6.246L3.134 8.28A1.995 1.995 0 0 0 3 9v9a2 2 0 0 0 2 2h13a2 2 0 0 0 2-2V9c0-.254-.047-.497-.134-.72L11.5 13.711z" fill="#626262"/></svg>
        </div>
        <div className="form_box get_quote">
          <input type="Password" placeholder="Password" onChange={this.onChange} name="password"/>

          <svg ><path fillOpacity=".886" d="M16 8a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3v-8a3 3 0 0 1 3-3V6.5a4.5 4.5 0 1 1 9 0V8zM7 9a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2H7zm8-1V6.5a3.5 3.5 0 0 0-7 0V8h7zm-3.5 6a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zm0-1a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5z" fill="#626262"/></svg>
        </div>
        <div  id="signup_box">
          <button type="button" onClick={()=>this.signup()} className="contact_btn  login_btn_ mb-0 ml-2 mt-0 mr-2 w-75">{lang.sign_up}</button>
        </div>

        <div>
          {lang.already_account} <Link to='/login'>{lang.log_in}</Link>
        </div>
    </div>
  </div>

</div>


<Footer />
</body>
</React.Fragment>
  )
}
}
export default Home;
