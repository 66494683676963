function sendFcmMessage({rider_id, orderId, message,}) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "key=AAAAeW_SEyQ:APA91bFMvD0EulHyJ6mcbeiKi057QoNNG_mMnez9EDU3Pb_UhnHLRKsHzpfG-Y2AVHM2FYdp_qYPbFSKLS12Cx3ffuzQZxacWTcSCPzV31qYjUK9BKkYz5aSTc8yHSH3Xtq2Q9EwS3XT");
  var raw = JSON.stringify({
    "to": `/topics/rider_${rider_id}`,
    "notification": {
      "body": `${orderId}:${message}`,
      "OrganizationId": "2",
      "content_available": true,
      "priority": "high",
      "subtitle": "TaslimExpress",
      "title": "You received a new message"
    },
    "data": {
      "priority": "high",
      "sound": "app_sound.wav",
      "content_available": true,
      "bodyText": "New Announcement assigned",
      "organization": " Taslim Express"
    }
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  return new Promise((resolve, reject) => {
    fetch("https://fcm.googleapis.com/fcm/send", requestOptions)
    .then(response => response.text())
    .then(result => resolve(result))
    .catch(error => reject(error));
  });
}
export {
  sendFcmMessage
};