import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import Header from './Header';
import Footer from './Footer';
class PickupRequest extends Component {
    constructor(){
    super();
    this.state = {
        redirectToReferrer: true,
        // orderData:this.props.location.state.data,
    };
}
componentDidUpdate()
{


}
componentDidMount(){


}

componentWillUnmount(){
       localStorage.removeItem('Data');
       localStorage.removeItem('History');
    }
// componentWillMount() {
//  if(sessionStorage.getItem("userData")){
//      this.getUserData();
//  }
//  else{
//      this.setState({redirectToReferrer: true});
//  }
// }
// getUserData() {
//  JSON.parse(sessionStorage.getItem("userData"));

// }
render(){
  const lang =JSON.parse(localStorage.getItem("lang"));
  const Data = JSON.parse(localStorage.getItem("Data"));
  const history = JSON.parse(localStorage.getItem("History"));
return(
<React.Fragment>


<div className="main_body register_main track_shipment_page" id="desktop_view">
    <div className="login_screen">
      <div className="row top_head co-one">
        <div className="col-sm-2 back_btn">
          {//<Link to="/">
            //<svg width="30px" height="30px"  viewBox="0 0 24 24"><path d="M16 8.414l-4.5-4.5L4.414 11H6v8h3v-6h5v6h3v-8h1.586L17 9.414V6h-1v2.414zM2 12l9.5-9.5L15 6V5h3v4l3 3h-3v7.998h-5v-6h-3v6H5V12H2z" fill="#fff"/></svg>
          //</Link>
        }
        </div>
        <div className="col-sm-8 page_title">
          <h4>{lang.track_ship_info}</h4>
        </div>
      </div>
      <form>
      <div className="track_details_page" id="tracking_row">
        <div className="fix_wrapper_h ">


             <ul>
        {Data && Data.map(item=>(
               <li>
                  <div className="row main_location fix_location tracking_info" id="click_btn">
              <div className="user_name_">
                <h3>{lang.tracking_result}</h3>
              </div>
              <div id="fix_top" className="shiping-consignee-bdr">
                <div className="w_10_px ">
                    <ul>
                      <li><i className="fa fa-map-marker"></i></li>
                    </ul>
                </div>
                <div className="w_90_px track-result">
                  <h3>{lang.shipper_info} </h3>
                  <p><b>{lang.shipper}:</b> {item.order_detail.sname} {item.order_detail.sbname && ' ( '+item.order_detail.sbname+' )'}</p>
                  <p><b>{lang.origin}:</b> {item.order_detail.origin}</p>
                </div>

              </div>

              <div id="fix_top">
                <div className="w_10_px">
                    <ul>
                      <li><i className="fa fa-user"></i></li>
                    </ul>
                  </div>
                  <div className="w_90_px track-result Consignee_box">
                    <h3>{lang.consigne_info}</h3>
                    <p><b>{lang.name}:</b> {item.order_detail.rname}</p>
                    <p><b>{lang.destination}:</b> {item.order_detail.destination}</p>
                  </div>
                </div>
                </div>
               </li>


          ))}
             </ul>








        </div>
    <div className="fix_wrapper_h">
      <div className="row main_location fix_location" id="click_btn">
        <div className="user_name_">
          <h3>{lang.tracking_history}</h3>
        </div>
        <div id="fix_top">
          <div className="w_10_px">
            <ul>
              <li><i className="fa fa-calendar"></i></li>
            </ul>
          </div>
          <div className="w_90_px status_box">
            <h3><span>{lang.date}</span> <b>{lang.status}</b></h3>

            {history && history.map((item)=>{
                return(

                  <p key={item.id}><span>{item.created_on}   </span>  <b>{item.order_status}</b></p>

                  )
              })

            })}

          </div>
        </div>
      </div>
    </div>
      </div>
     </form>

  </div>
</div>



<Footer />

</React.Fragment>
    )
}
}
export default PickupRequest;
