import React, {Component} from 'react';
// import {Redirect} from 'react-router-dom';
import {Link} from 'react-router-dom';
import background from '../assets/images/loader-bg.png';
import splashscreen from '../assets/images/fast-courier-banner.jpg';
import loginScreen from '../assets/images/login-screen.jpg';
import site_logo from '../assets/images/header_log.png';
import site_logo_ from '../assets/images/white-black-logo.png';
import site_logo_white from '../assets/images/Footer-Logo-White-300x218.png';
import loader from '../assets/images/770.gif';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {api_url} from '../Config';
import swal from 'sweetalert';
import {english} from '../component/English';
import {kurdish} from '../component/Kurdish';
import {arabic} from '../component/Arabic';
import CommonHeader from '../component/CommonHeader';

class Home extends Component {
  constructor(){
    super();
    this.state = {
        redirectToReferrer: false,
        base_url:api_url.base_url,
        loading:false,
        order_id:'',
        orderData:[],
        historyData:[],
        idErr:'',
        track_no:'',
        site_logo:'',
        company_name:'',
        english:english,
        kurdish:kurdish,
        arabic:arabic,
        whats_app_number:''
    };
    let lang_name = localStorage.getItem('lang_name');
    if (lang_name ==null) {
       localStorage.setItem('lang',JSON.stringify(this.state.kurdish));
       localStorage.setItem('lang_name','kurdish');
    }

    this.onChange = this.onChange.bind(this);
    this.getShipmentDetails = this.getShipmentDetails.bind(this);
    var api_rul_info = this.state.base_url+'api.php?type=get_site_info';
        fetch(api_rul_info)
        .then(res=>res.json())
        .then(result=>{
            if (result.response==1) {
                this.setState({
                    site_logo:result.site_logo,
                    company_name:result.company_name
                })

            }
        })
}
componentDidMount(){
  var WHATSAPPURL = this.state.base_url + 'customer_api.php?type=whatsappNo';
  fetch(WHATSAPPURL)
      .then(res => res.json())
      .then(result => {
          this.setState({
            whats_app_number: "https://wa.me/"+result.number
          })
      })
// $(window).load(function() {
    $(".loader").delay(3400).fadeOut("slow");
  $("#overlayer").delay(3400).fadeOut("slow");
// });
// $(window).load(function() {
    $(".loader").delay(3400).fadeOut("slow");
  $("#overlayer").delay(3400).fadeOut("slow");
// });



setTimeout(() => {
    var string = this.state.company_name;
var array = string.split("");
var timer;

function frameLooper () {
  if (array.length > 0) {
    document.getElementById("text").innerHTML += array.shift();
  } else {
    clearTimeout(timer);
      }
  setTimeout(()=>frameLooper(),150);

}
frameLooper();
  }, 1000);




}
getShipmentDetails(e) {
    e.preventDefault();
    if (this.state.order_id==='') {
      this.setState({
        idErr:'Please enter Track No.'
      })
    }else{

      this.setState({loading:true})

      const sendData = {
          track_no:this.state.order_id
      }
      const trackURL = this.state.base_url+'api.php?type=track_shipments';
      fetch(trackURL,
      {
          method: 'POST',
          headers:
          {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
          },
          body:JSON.stringify(sendData)
      })
      .then((response) => response.json())
      .then(result=>{
       if(result.response===1)
       {
           this.setState({
               orderData:result.result,
               historyData:result.history,
               track_no:result.result[0].order_detail.track_no

           },()=>{
               this.setState({redirectToReferrer:true})
           });
       }else{
         swal('No Record Found.');
       }
       this.setState({loading:false});
      })
      .catch((error) => {
          this.setState({loading:false});
      });
    }
}
onChange(e){
   this.setState({[e.target.name]:e.target.value});
}

render(){
  let lang_name = localStorage.getItem('lang_name');
    if (lang_name ==null) {
       localStorage.setItem('lang',JSON.stringify(this.state.english));
       localStorage.setItem('lang_name','english');
    }
  const lang = JSON.parse(localStorage.getItem("lang")) ? JSON.parse(localStorage.getItem("lang")) : [];
const {loading} = this.state;
    if (this.state.redirectToReferrer) {
      return(<Redirect to={`/track-detail/${this.state.track_no}`}
          />)
    }
    if(localStorage.getItem('customer_id') > 0 )
      {
        return(<Redirect to='dashboard' />)
      }
return(

<React.Fragment>
  <div className="home_bg ">
    <div className="whatsapp">
        <a href={this.state.whats_app_number} target="_blank"><i className="fa fa-whatsapp"></i></a>
      </div>
    <div className="row top_head co-one"id="mian_home" style={{background:'#282828'}}>
      <div className="col-sm-2 back_btn toggle_Btn">

      </div>
      <div className="col-sm-8 page_title">
        <h4>{this.state.company_name}</h4>
      </div>
      <div className="col-sm-2 right_logo">
          <img src={site_logo_white} alt=""/>
        </div>
    </div>
  <div className="main_body_">
  <div className="offcanvas-overlay"></div>
  <span className="loader loder_black">
<img className="splash_screen"  src={splashscreen} alt="" />
    <div className="typing_text">
      <img className="hide_mobile" src={site_logo} alt="" />
      <img className="desktop_hide" src={site_logo_} alt="" />
      <div id="text"></div>
    </div>
  </span>
  
<div className="login_screen fix_screen" id="register_screen">

 


    {loading ?
        <div className="form_loader">
                 <img src={loader} alt="" />
            </div>
        :
         <React.Fragment>

    <div className=" desktop_layout ">
    

    <div className="dashb_icons">
      <ul>
        
        {
          localStorage.getItem('consignee_id') ?
          <li>
          <Link to="/track-listing" className="rate_bg">

            {lang.tracking_history}
            <svg viewBox="0 0 24 24"><path d="M11.5 6a7.5 7.5 0 1 1 0 15a7.5 7.5 0 0 1 0-15zm0 1a6.5 6.5 0 1 0 0 13a6.5 6.5 0 0 0 0-13zM11 9h1v4.363l3.048 1.421l-.423.906L11 14V9zm4.25-3.75l.643-.766l3.83 3.214l-.643.766l-3.83-3.214zm-7.5 0L3.92 8.464l-.643-.766l3.83-3.214l.643.766z" fill="#1c2c4d"/></svg>
          </Link>
        </li>

          :

          <li>
          <Link to="/login" className="rate_bg">

            {lang.tracking_history}
            <svg viewBox="0 0 24 24"><path d="M11.5 6a7.5 7.5 0 1 1 0 15a7.5 7.5 0 0 1 0-15zm0 1a6.5 6.5 0 1 0 0 13a6.5 6.5 0 0 0 0-13zM11 9h1v4.363l3.048 1.421l-.423.906L11 14V9zm4.25-3.75l.643-.766l3.83 3.214l-.643.766l-3.83-3.214zm-7.5 0L3.92 8.464l-.643-.766l3.83-3.214l.643.766z" fill="#1c2c4d"/></svg>
          </Link>


        </li>

        }

        <li>
          <Link to="from-me-shipment" className="rate-transit">
              {lang.busingess_dashboard}
            <svg viewBox="0 0 24 24"><path d="M6 5h2.5a3 3 0 1 1 6 0H17a3 3 0 0 1 3 3v11a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3zm0 1a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-1v3H7V6H6zm2 2h7V6H8v2zm3.5-5a2 2 0 0 0-2 2h4a2 2 0 0 0-2-2zm5.65 8.6L10 18.75l-3.2-3.2l.707-.707L10 17.336l6.442-6.443l.707.707z" fill="#1c2c4d"></path></svg>
            </Link>
        </li>
        
        <li>
            <Link to="/rates" className="rate_bg">
              {lang.get_quote}
              <svg viewBox="0 0 24 24"><path d="M5.5 14a2.5 2.5 0 0 1 2.45 2H15V6H4a2 2 0 0 0-2 2v8h1.05a2.5 2.5 0 0 1 2.45-2zm0 5a2.5 2.5 0 0 1-2.45-2H1V8a3 3 0 0 1 3-3h11a1 1 0 0 1 1 1v2h3l3 3.981V17h-2.05a2.5 2.5 0 0 1-4.9 0h-7.1a2.5 2.5 0 0 1-2.45 2zm0-4a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zm12-1a2.5 2.5 0 0 1 2.45 2H21v-3.684L20.762 12H16v2.5a2.49 2.49 0 0 1 1.5-.5zm0 1a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zM16 9v2h4.009L18.5 9H16z" fill="#1c2c4d"></path></svg>
            </Link>
        </li>
        <li>
            <Link to="/contact-us" className="service_bg">
              {lang.contact_us}
              <svg viewBox="0 0 24 24"><path d="M19.5 22c.827 0 1.5-.673 1.5-1.5V17c0-.827-.673-1.5-1.5-1.5c-1.17 0-2.32-.184-3.42-.547a1.523 1.523 0 0 0-1.523.363l-1.44 1.44a14.655 14.655 0 0 1-5.885-5.883L8.66 9.436c.412-.382.56-.963.384-1.522A10.872 10.872 0 0 1 8.5 4.5C8.5 3.673 7.827 3 7 3H3.5C2.673 3 2 3.673 2 4.5C2 14.15 9.85 22 19.5 22zM3.5 4H7a.5.5 0 0 1 .5.5c0 1.277.2 2.531.593 3.72a.473.473 0 0 1-.127.497L6.01 10.683c1.637 3.228 4.055 5.646 7.298 7.297l1.949-1.95a.516.516 0 0 1 .516-.126c1.196.396 2.45.596 3.727.596c.275 0 .5.225.5.5v3.5c0 .275-.225.5-.5.5C10.402 21 3 13.598 3 4.5a.5.5 0 0 1 .5-.5z" fill="#1c2c4d"></path></svg>
            </Link>
        </li>


        <li>
            <Link to="/track-parcel" className="service_bg">
              Track Parcel
              <svg viewBox="0 0 24 24"><path d="M11.5 7a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5zm0 1a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zm-4.7 4.357l4.7 7.73l4.7-7.73a5.5 5.5 0 1 0-9.4 0zm10.254.52L11.5 22.012l-5.554-9.135a6.5 6.5 0 1 1 11.11 0h-.002z" fill="#1c2c4d"/></svg>
            </Link>
        </li>

        <li>
            <Link to="/from-me-shipment" className="service_bg">

              {lang.booking}
              <svg viewBox="0 0 24 24"><path d="M7 2h1a1 1 0 0 1 1 1v1h5V3a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a3 3 0 0 1 3 3v11a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3V3a1 1 0 0 1 1-1zm8 2h1V3h-1v1zM8 4V3H7v1h1zM6 5a2 2 0 0 0-2 2v1h15V7a2 2 0 0 0-2-2H6zM4 18a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V9H4v9zm8-5h5v5h-5v-5zm1 1v3h3v-3h-3z" fill="#1c2c4d"/></svg>
            </Link>
        </li>


      </ul>
    </div>



      {
    //     <div className="login_btm">
    //   <img src={loginScreen} alt=""/>
    // </div>
      }
    </div>
    </React.Fragment>
}
  </div>
</div>
</div>
</React.Fragment>
  )
}
}
export default Home;
