import { initializeApp, } from "@firebase/app";
import {getDatabase, ref, onValue } from "@firebase/database";
const firebaseConfig = {
    apiKey: "AIzaSyCnyfN8kpm8uNvj3NRvEFIGXQi9jS4MB7I",
    authDomain: "taslim-express-iraq.firebaseapp.com",
    databaseURL: "https://taslim-express-iraq-default-rtdb.firebaseio.com",
    projectId: "taslim-express-iraq",
    storageBucket: "taslim-express-iraq.appspot.com",
    messagingSenderId: "521567081252",
    appId: "1:521567081252:web:392611f37f6561f8b7d9b1",
    measurementId: "G-6JRYJZS90P"
  };
  
   
const app = initializeApp(firebaseConfig);
var db = getDatabase(app);

export default db;
