import React, {Component} from 'react';
import { Link, Redirect  } from 'react-router-dom';
import $ from 'jquery';
import {api_url} from '../Config';
import Header from './Header';
import Footer from './Footer';
import loader from '../assets/images/770.gif';
import CommonHeader from '../component/CommonHeader';
class PickupRequest extends Component {
constructor(){
super();
this.state = {
redirectToReferrer: false,
track_no:'',
base_url:api_url.base_url,
trackHistory:[],
trackData:[],
loading:true,
translation_array:[]
// orderData:this.props.location.state.data,
};

this.addToListing = this.addToListing.bind(this);
this.checkLogin = this.checkLogin.bind(this);
}

componentDidMount(){

    let lang_name = localStorage.getItem('lang_name');
let trackDatasend = {
            consignee_id:localStorage.getItem('consignee_id'),
            track_no:this.props.match.params.track_no,
            lang:lang_name
        }
    // console.log(trackData);
      const addUrl = this.state.base_url+'api.php?type=track_shipment';
        fetch(addUrl,
        {

            method: 'POST',
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify(trackDatasend)
        })
        .then((response) => response.json()
            .then((result) => {
               this.setState({
                 trackHistory:result.history,
                 trackData:result.result,
                 'translation_array':result.translation,
                 loading:false,
               });
               localStorage.setItem("trnas_array",JSON.stringify(result.translation));
        }))
        .catch((error) => {
            this.setState({loading:false})
        });

}
addToListing(track_no)
{
    let addData = {
        track_no:track_no,
        'consignee_id':localStorage.getItem('consignee_id')
    }
    const addUrl = this.state.base_url+'consignee_api.php?type=save_record';
    fetch(addUrl,
    {

        method: 'POST',
        headers:
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body:JSON.stringify(addData)
    })
    .then((response) => response.json()
        .then((result) => {
            this.setState({
                redirectToReferrer:true,
              });
    }))
    .catch((error) => {

        });
}

checkLogin(track_no)
{
    this.setState({
        track_no:track_no
    })
}
render(){
      const lang =JSON.parse(localStorage.getItem("lang"));
      const lang_array = JSON.parse(localStorage.getItem("trnas_array")) ? JSON.parse(localStorage.getItem("trnas_array")) : [];
const data = this.state.trackData;

if(this.state.track_no!==''){
    localStorage.setItem('track_no',this.state.track_no);
    return(<Redirect to='/login' />);
}
if(this.state.redirectToReferrer)
  {
    return(<Redirect to='/track-listing' />)
  }
return(
<React.Fragment>
<div className="main_body register_main track_shipment_page" id="desktop_view">
    <div className="login_screen">
        <CommonHeader />

    {
        this.state.loading?
        <img className="loader_onorder" src={loader} alt="Loading data" />
        :
        <form key={data.track_no}>
        <div className="track_details_page" id="track_details">
            <div className="fix_wrapper_h sro_record">
                <div className="row main_location fix_location" id="click_btn">
                    <div className="user_name_">
                        <h3> {lang.tracking_result} {data.track_no}

                        {localStorage.getItem('consignee_id')

                        ?

                        <button type="button" onClick={()=>this.addToListing(data.track_no)} className="save_future">{lang.save}</button>
                        :

                        <button onClick={()=>this.checkLogin(data.track_no)} type="button" className="save_future">{lang.save}</button>

                        }

                        </h3>
                    </div>
                    <div id="fix_top" className="shiping-consignee-bdr">
                        <div className="w_10_px ">
                            <ul>
                                <li><i className="fa fa-map-marker"></i></li>
                            </ul>
                        </div>
                        <div className="w_90_px track-result">
                            <h3>{lang.shipper_info} </h3>
                            {/*<p><b style={{color:'#416baf'}}>{lang.shipper}:</b> {data.sname} {data.sbname && ' ( '+data.sbname+' )'}</p>*/}
                            <p><b style={{color:'#416baf'}}>{lang.shipper}:</b> {data.sbname ? data.sbname : data.sname}</p>
                            <p><b style={{color:'#416baf'}}>{lang.origin}:</b> {data.origin}</p>
                        </div>
                    </div>
                    <div id="fix_top">
                        <div className="w_10_px">
                            <ul>
                                <li><i className="fa fa-user"></i></li>
                            </ul>
                        </div>
                        <div className="w_90_px track-result Consignee_box">
                            <h3>{lang.consigne_info}</h3>
                            <p><b style={{color:'#416baf'}}>{lang.name}:</b> {data.rname}</p>
                            <p><b style={{color:'#416baf'}}>{lang.destination}:</b> {data.destination}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fix_wrapper_h" id="tracking_status">
                <div className="row main_location fix_location" id="click_btn">
                    <div className="user_name_">
                        <h3>{lang.tracking_history} {data.track_no}</h3>
                    </div>
                    <div id="fix_top">
                        <div className="w_10_px">
                            <ul>
                                <li><i className="fa fa-calendar"></i></li>
                            </ul>
                        </div>
                        <div className="w_90_px status_box">
                            <h3><span>{lang.date}</span> <b>{lang.status}</b></h3>
                            {
                            this.state.trackHistory.map(item=>{
                                var status_name = item.order_status ? item.order_status.trim() :'';

                                        lang_array.length> 0 && lang_array.map(trnas_val=>{
                                            if(trnas_val.keyword==status_name.trim()){
                                            console.log(trnas_val.keyword)

                                                status_name = trnas_val.translation;
                                            }
                                        })
                            return(
                            <p key={item.id}><span>{item.created_on}   </span>  <b>{status_name}</b></p>
                            )
                            })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    }

</div>
</div>
<Footer />
</React.Fragment>
)
}
}
export default PickupRequest;
