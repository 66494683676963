import React, {Component} from 'react';
import { Redirect, Link} from 'react-router-dom';
import {api_url} from '../Config';
import swal from 'sweetalert';
import chat_avatar1 from '../assets/images/chat_avatar_01.jpg';
import chat_avatar2 from '../assets/images/chat_avatar_02.jpg';
import chat_avatar3 from '../assets/images/chat_avatar_03.jpg';
import chat_avatar4 from '../assets/images/chat_avatar_04.jpg';
import Footer from './Footer';
import CommonHeader from '../component/CommonHeader';
class ChatDetails extends Component {
  constructor(){
    super();
    this.state = {
        redirectToReferrer: false,
        base_url:api_url.base_url,
        email:'',
        password:'',
    };
    this.save_record = this.save_record.bind(this);
    this.onChange = this.onChange.bind(this);
    this.login = this.login.bind(this);
}
componentDidMount(){


}


login()
{
  if(this.state.email ===''|| this.state.password==='')
  {
    alert('Email and Password required.')
  }else{
            let signupData = {
            email:this.state.email,
            'password':this.state.password
        }
        const addUrl = this.state.base_url+'consignee_api.php?type=consignee_login';
        fetch(addUrl,
        {

            method: 'POST',
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify(signupData)
        })
        .then((response) => response.json()
            .then((result) => {
               if(result.consignee_id > 0)
               {
                 localStorage.setItem('consignee_id',result.consignee_id);
                 this.save_record(result.consignee_id);
               }else{
                 swal('Invalid Details.')
               }
        }))
        .catch((error) => {

            });
  }


}
save_record()
{
  let addData = {
        track_no:localStorage.getItem('track_no'),
        'consignee_id':localStorage.getItem('consignee_id')
    }
    const addUrl = this.state.base_url+'consignee_api.php?type=save_record';
    fetch(addUrl,
    {

        method: 'POST',
        headers:
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body:JSON.stringify(addData)
    })
    .then((response) => response.json()
        .then((result) => {
            if(result.response===1){
              this.setState({
                redirectToReferrer:true,
              })
            }
    }))
    .catch((error) => {

        });
}
onChange(e){
   this.setState({[e.target.name]:e.target.value});
}
render(){
  const lang =JSON.parse(localStorage.getItem("lang"));
if(this.state.redirectToReferrer)
  {
    return(<Redirect to='track-listing' />)
  }
return(

<React.Fragment>
  <body id="page-name">
<CommonHeader />


<div className="formbox dashboard_page chat_box" id="active_box">
   <div className="popup_box">
      <div className=" clearfix">
         <div className="people-list mobile_hide_box" id="people-list">
            <div className="search">
               <input type="text" placeholder="search" />
               <i className="fa fa-search"></i>
            </div>
            <ul className="list">
               <li className="clearfix">
                  <Link to="#">
                    <img src={chat_avatar1} alt=""/>
                     <div className="about">
                        <div className="status">
                           <i className="fa fa-circle online"></i> #B09876892
                        </div>
                        <div className="date_box">
                           10-12-23 / 3:57 PM
                        </div>
                        <div className="name">Vincent Porter</div>
                     </div>
                  </Link>
               </li>
               <li className="clearfix">
                  <Link to="#">
                    <img src={chat_avatar2} alt=""/>
                     <div className="about">
                        <div className="status">
                           <i className="fa fa-circle offline"></i>  #A09855892
                        </div>
                        <div className="name">Aiden Chavez</div>
                        <div className="date_box">
                           13-12-23 / 3:57 PM
                        </div>
                     </div>
                  </Link>
               </li>
               <li className="clearfix">
                  <Link to="#">
                    <img src={chat_avatar3} alt=""/>
                     <div className="about">
                        <div className="status">
                           <i className="fa fa-circle online"></i> #C85855892
                        </div>
                        <div className="name">Mike Thomas</div>
                        <div className="date_box">
                           14-12-23 / 3:57 PM
                        </div>
                     </div>
                  </Link>
               </li>
               <li className="clearfix">
                  <Link to="#">
                     <img src={chat_avatar4} alt=""/>
                     <div className="about">
                        <div className="status">
                           <i className="fa fa-circle online"></i> #C85855892
                        </div>
                        <div className="date_box">
                           16-12-23 / 3:57 PM
                        </div>
                        <div className="name">Erica Hughes</div>
                     </div>
                  </Link>
               </li>
               <li className="clearfix">
                  <Link to="#">
                     <img src={chat_avatar2} alt=""/>
                     <div className="about">
                        <div className="status">
                           <i className="fa fa-circle online"></i> #B09876892
                        </div>
                        <div className="date_box">
                           10-12-23 / 3:57 PM
                        </div>
                        <div className="name">Vincent Porter</div>
                     </div>
                  </Link>
               </li>
               <li className="clearfix">
                  <Link to="#">
                     <img src={chat_avatar4} alt=""/>
                     <div className="about">
                        <div className="status">
                           <i className="fa fa-circle offline"></i>  #A09855892
                        </div>
                        <div className="name">Aiden Chavez</div>
                        <div className="date_box">
                           13-12-23 / 3:57 PM
                        </div>
                     </div>
                  </Link>
               </li>
               <li className="clearfix">
                  <Link to="#">
                     <img src={chat_avatar3} alt=""/>
                     <div className="about">
                        <div className="status">
                           <i className="fa fa-circle online"></i> #C85855892
                        </div>
                        <div className="name">Mike Thomas</div>
                        <div className="date_box">
                           14-12-23 / 3:57 PM
                        </div>
                     </div>
                  </Link>
               </li>
               <li className="clearfix">
                  <Link to="#">
                     <img src={chat_avatar2} alt=""/>
                     <div className="about">
                        <div className="status">
                           <i className="fa fa-circle online"></i> #C85855892
                        </div>
                        <div className="date_box">
                           16-12-23 / 3:57 PM
                        </div>
                        <div className="name">Erica Hughes</div>
                     </div>
                  </Link>
               </li>
               <li className="clearfix">
                  <Link to="#">
                     <img src={chat_avatar2} alt=""/>
                     <div className="about">
                        <div className="status">
                           <i className="fa fa-circle online"></i> #B09876892
                        </div>
                        <div className="date_box">
                           10-12-23 / 3:57 PM
                        </div>
                        <div className="name">Vincent Porter</div>
                     </div>
                  </Link>
               </li>
               <li className="clearfix">
                  <Link to="#">
                     <img src={chat_avatar4} alt=""/>
                     <div className="about">
                        <div className="status">
                           <i className="fa fa-circle offline"></i>  #A09855892
                        </div>
                        <div className="name">Aiden Chavez</div>
                        <div className="date_box">
                           13-12-23 / 3:57 PM
                        </div>
                     </div>
                  </Link>
               </li>
               <li className="clearfix">
                  <Link to="#">
                     <img src={chat_avatar3} alt=""/>
                     <div className="about">
                        <div className="status">
                           <i className="fa fa-circle online"></i> #C85855892
                        </div>
                        <div className="name">Mike Thomas</div>
                        <div className="date_box">
                           14-12-23 / 3:57 PM
                        </div>
                     </div>
                  </Link>
               </li>
               <li className="clearfix">
                  <Link to="#">
                     <img src={chat_avatar2} alt=""/>
                     <div className="about">
                        <div className="status">
                           <i className="fa fa-circle online"></i> #C85855892
                        </div>
                        <div className="date_box">
                           16-12-23 / 3:57 PM
                        </div>
                        <div className="name">Erica Hughes</div>
                     </div>
                  </Link>
               </li>
               <li className="clearfix">
                  <Link to="#">
                     <img src={chat_avatar2} alt=""/>
                     <div className="about">
                        <div className="status">
                           <i className="fa fa-circle online"></i> #B09876892
                        </div>
                        <div className="date_box">
                           10-12-23 / 3:57 PM
                        </div>
                        <div className="name">Vincent Porter</div>
                     </div>
                  </Link>
               </li>
               <li className="clearfix">
                  <Link to="#">
                     <img src={chat_avatar4} alt=""/>
                     <div className="about">
                        <div className="status">
                           <i className="fa fa-circle offline"></i>  #A09855892
                        </div>
                        <div className="name">Aiden Chavez</div>
                        <div className="date_box">
                           13-12-23 / 3:57 PM
                        </div>
                     </div>
                  </Link>
               </li>
               <li className="clearfix">
                  <Link to="#">
                     <img src={chat_avatar3} alt=""/>
                     <div className="about">
                        <div className="status">
                           <i className="fa fa-circle online"></i> #C85855892
                        </div>
                        <div className="name">Mike Thomas</div>
                        <div className="date_box">
                           14-12-23 / 3:57 PM
                        </div>
                     </div>
                  </Link>
               </li>
               <li className="clearfix">
                  <Link to="#">
                     <img src={chat_avatar2} alt=""/>
                     <div className="about">
                        <div className="status">
                           <i className="fa fa-circle online"></i> #C85855892
                        </div>
                        <div className="date_box">
                           16-12-23 / 3:57 PM
                        </div>
                        <div className="name">Erica Hughes</div>
                     </div>
                  </Link>
               </li>
               <li className="clearfix">
                  <Link to="#">
                     <img src={chat_avatar2} alt=""/>
                     <div className="about">
                        <div className="status">
                           <i className="fa fa-circle online"></i> #B09876892
                        </div>
                        <div className="date_box">
                           10-12-23 / 3:57 PM
                        </div>
                        <div className="name">Vincent Porter</div>
                     </div>
                  </Link>
               </li>
               <li className="clearfix">
                  <Link to="#">
                     <img src={chat_avatar4} alt=""/>
                     <div className="about">
                        <div className="status">
                           <i className="fa fa-circle offline"></i>  #A09855892
                        </div>
                        <div className="name">Aiden Chavez</div>
                        <div className="date_box">
                           13-12-23 / 3:57 PM
                        </div>
                     </div>
                  </Link>
               </li>
               <li className="clearfix">
                  <Link to="#">
                     <img src={chat_avatar3} alt=""/>
                     <div className="about">
                        <div className="status">
                           <i className="fa fa-circle online"></i> #C85855892
                        </div>
                        <div className="name">Mike Thomas</div>
                        <div className="date_box">
                           14-12-23 / 3:57 PM
                        </div>
                     </div>
                  </Link>
               </li>
               <li className="clearfix">
                  <Link to="#">
                     <img src={chat_avatar2} alt=""/>
                     <div className="about">
                        <div className="status">
                           <i className="fa fa-circle online"></i> #C85855892
                        </div>
                        <div className="date_box">
                           16-12-23 / 3:57 PM
                        </div>
                        <div className="name">Erica Hughes</div>
                     </div>
                  </Link>
               </li>
            </ul>
         </div>
         <div className="chat chat_box_hide" id="show_list_chat">
            <div className="chat-header clearfix">
               <img src={chat_avatar1} alt=""/>
               <div className="chat-about">
                  <div className="chat-with">Vincent Porter</div>
                  <div className="chat-num-messages">already 1 902 messages</div>
               </div>
               <b>#B09876892</b>
            </div>
           
            <div className="chat-history">
               <ul>
                  <li className="clearfix">
                     <div className="message-data align-right">
                        <span className="message-data-time">10:10 AM, Today</span> &nbsp; &nbsp;
                        <span className="message-data-name">Olia</span> <i className="fa fa-circle me"></i>
                     </div>
                     <div className="message other-message float-right">
                        Hi Vincent, how are you? How is the project coming along?
                     </div>
                  </li>
                  <li>
                     <div className="message-data">
                        <span className="message-data-name"><i className="fa fa-circle online"></i> Vincent</span>
                        <span className="message-data-time">10:12 AM, Today</span>
                     </div>
                     <div className="message my-message">
                        Are we meeting today? Project has been already finished and I have results to show you.
                     </div>
                  </li>
                  <li className="clearfix">
                     <div className="message-data align-right">
                        <span className="message-data-time">10:14 AM, Today</span> &nbsp; &nbsp;
                        <span className="message-data-name">Olia</span> <i className="fa fa-circle me"></i>
                     </div>
                     <div className="message other-message float-right">
                        Well I am not sure. The rest of the team is not here yet. Maybe in an hour or so? Have you faced any problems at the last phase of the project?
                     </div>
                  </li>
                  <li>
                     <div className="message-data">
                        <span className="message-data-name"><i className="fa fa-circle online"></i> Vincent</span>
                        <span className="message-data-time">10:20 AM, Today</span>
                     </div>
                     <div className="message my-message">
                        Actually everything was fine. I'm very excited to show this to our team.
                     </div>
                  </li>
                  <li>
                     <div className="message-data">
                        <span className="message-data-name"><i className="fa fa-circle online"></i> Vincent</span>
                        <span className="message-data-time">10:31 AM, Today</span>
                     </div>
                     <i className="fa fa-circle online"></i>
                     <i className="fa fa-circle online" style={{color:'#AED2A6'}}></i>
                     <i className="fa fa-circle online" style={{color:'#DAE9DA'}}></i>
                  </li>
                  <li className="clearfix">
                     <div className="message-data align-right">
                        <span className="message-data-time">10:14 AM, Today</span> &nbsp; &nbsp;
                        <span className="message-data-name">Olia</span> <i className="fa fa-circle me"></i>
                     </div>
                     <div className="message other-message float-right">
                        Well I am not sure. The rest of the team is not here yet. Maybe in an hour or so? Have you faced any problems at the last phase of the project?
                     </div>
                  </li>
               </ul>
            </div>
            
            <div className="chat-message clearfix">
               <textarea name="message-to-send" id="message-to-send" placeholder="Type your message" rows="3"></textarea>
              <button><i className="fa fa-send-o"></i></button>
            </div>
         </div>
      </div>
   </div>
</div>


<Footer />

</body>
</React.Fragment>
  )
}
}
export default ChatDetails;
