import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import $ from 'jquery';
import { api_url } from '../Config';
import Header from './Header';
import Footer from './Footer';
import loader from '../assets/images/770.gif';
import CustomerHeader from '../component/CustomerHeader';
class PickupRequest extends Component {
    constructor() {
        super();
        this.state = {
            redirectToReferrer: false,
            track_no: '',
            base_url: api_url.base_url,
            allData: [],
            history: [],
            'loading': true
        };

        this.checkLogin = this.checkLogin.bind(this);
    }

    componentDidMount() {

        let trackData = {
            customer_id: localStorage.getItem('customer_id'),
            track_no: this.props.match.params.track_no
        }
        const addUrl = this.state.base_url + 'customer_api.php?type=track_one_order';
        fetch(addUrl,
            {
                method: 'POST',
                headers:
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(trackData)
            })
            .then((response) => response.json()
                .then((result) => {
                    this.setState({
                        allData: result.orders,
                        history: result.history,
                        loading: false
                    })
                }))
            .catch((error) => {
                this.setState({ loading: false })
            });

    }
    componentWillUnmount() {
        localStorage.removeItem('data');
    }

    checkLogin(track_no) {
        this.setState({
            track_no: track_no
        });
    }
    render() {
        const lang = JSON.parse(localStorage.getItem("lang"));
        const lang_array = JSON.parse(localStorage.getItem("trnas_array")) ? JSON.parse(localStorage.getItem("trnas_array")) : [];
        var source_name = '';
        function source_name_get(name) {
            if (name == 1) {
                source_name = 'API';
            }
            else if (name == 2) {
                source_name = 'Admin Booking';
            }
            else if (name == 3) {
                source_name = 'Bulk Booking';
            }
            else if (name == 4) {
                source_name = 'Customer Booking';
            }
            else if (name == 5) {
                source_name = 'App Booking';
            }

            return source_name;

        }
        const data = JSON.parse(localStorage.getItem("data"));

        if (this.state.track_no !== '') {
            localStorage.setItem('track_no', this.state.track_no);
            return (<Redirect to='/login' />);
        }
        if (this.state.redirectToReferrer) {
            return (<Redirect to='track-listing' />)
        }
        return (
            <React.Fragment>
                <div className="main_body register_main track_shipment_page" id="desktop_view">
                    <div className="login_screen">
                        <CustomerHeader title='Order Detail' />

                        {
                            this.state.loading ?
                                <img className="loader_onorder" src={loader} alt="Loading data" />
                                :

                                <form className="sr_info">
                                    <div className="track_details_page" id="track_details">
                                        <div className="fix_wrapper_h sro_record">
                                            <div className="row main_location fix_location" id="click_btn">
                                                <div className="user_name_">
                                                    <h3>{lang.tracking_no} <span>{this.state.allData.track_no}</span> </h3>
                                                </div>
                                                <div id="fix_top" className="shiping-consignee-bdr">
                                                    <div className="w_10_px ">
                                                        <ul>
                                                            <li><i className="fa fa-paper-plane"></i></li>
                                                        </ul>
                                                    </div>
                                                    <div className="w_90_px track-result">
                                                        <h3>{lang.sender_information}</h3>
                                                        <p>{this.state.allData.sname}</p>
                                                        <p>{this.state.allData.sender_address}</p>
                                                        <p>{this.state.allData.sphone}</p>
                                                    </div>
                                                </div>
                                                <div id="fix_top">
                                                    <div className="w_10_px">
                                                        <ul>
                                                            <li><i className="fa fa-envelope"></i></li>
                                                        </ul>
                                                    </div>
                                                    <div className="w_90_px track-result Consignee_box">
                                                        <h3>{lang.receiver_information}</h3>
                                                        <p>{this.state.allData.rname}</p>
                                                        <p>{this.state.allData.rphone}</p>
                                                        <p>{this.state.allData.receiver_address}</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="fix_wrapper_h sro_record order_info_track">
                                            <div className="row main_location fix_location" id="click_btn">
                                                <div className="user_name_">
                                                    <h3>{lang.order_iformation} </h3>
                                                </div>
                                                <div id="fix_top" className="order_Info_box">

                                                    <div className="w_90_px track-result Consignee_box">

                                                        <p>{this.state.allData.order_time}</p>
                                                        <p>
                                                            <b>
                                                                <i className="fa fa-lightbulb-o"></i>
                                                                {this.state.allData.service_code} -
                                                                <i className="fa fa-balance-scale"></i>
                                                                {this.state.allData.weight} {lang.kg}
                                                            </b>
                                                        </p>

                                                        <p>{this.state.allData.origin} <b className="distance">To</b> {this.state.allData.destination}</p>
                                                        <p><b style={{ color: '#416baf' }}>{lang.item_detail}</b> <span>{this.state.allData.product_desc}</span></p>
                                                        <p><b style={{ color: '#416baf' }}>{lang.order_src}</b> <span>{
                                                            source_name_get(this.state.allData.order_type_booking)
                                                        }</span></p>
                                                        <p><b style={{ color: '#416baf' }}>{lang.cod_amount}: {this.state.allData.collection_amount}</b></p>
                                                        <p><b >{lang.status} {this.state.allData.status}</b></p>
                                                        {
                                                            this.state.allData.status_reason &&

                                                            <p><b>{"(" + this.state.allData.status_reason + ")"} </b></p>
                                                        }

                                                        {
                                                            // <p><b>Action:</b> <span></span></p>
                                                        }
                                                        {
                                                            //     <p className="action_box"><a title="view order"  target="_blank" href="#"><svg className="view_action_btn" viewBox="0 0 24 24"><path d="M16 12a3 3 0 0 1-3-3V5H5a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h13a2 2 0 0 0 2-2v-6h-4zm-2-3a2 2 0 0 0 2 2h3.586L14 5.414V9zM5 4h9l7 7v7a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3zm0 4h6v1H5V8zm0 4h6v1H5v-1zm0 4h13v1H5v-1z" fill="#e11a28"></path></svg></a>
                                                            // <a href="#"><svg className="view_action_btn" viewBox="0 0 24 24"><path d="M7 3h9a3 3 0 0 1 3 3v13a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3zm0 1a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H7zm0 1h9a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1zm0 1v12h9V6H7zm2 2h5v1H9V8zm0 3h5v1H9v-1zm0 3h3v1H9v-1z" fill="#e11a28"/></svg></a>
                                                            // <a target="_blank"  title="edit" href="#"> <svg className="view_action_btn" viewBox="0 0 24 24"><path d="M19.706 8.042l-2.332 2.332l-3.75-3.75l2.332-2.332a.999.999 0 0 1 1.414 0l2.336 2.336a.999.999 0 0 1 0 1.414zM2.999 17.248L13.064 7.184l3.75 3.75L6.749 20.998H3v-3.75zM16.621 5.044l-1.54 1.539l2.337 2.335l1.538-1.539l-2.335-2.335zm-1.264 5.935l-2.335-2.336L4 17.664V20h2.336l9.021-9.021z" fill="#e11a28"></path></svg></a>
                                                            // <a  title="cancel order" href="#"><svg className="view_action_btn" viewBox="0 0 24 24"><path d="M9 6.5c0 .786-.26 1.512-.697 2.096L20 20.293V21h-.707L11.5 13.207l-3.197 3.197a3.5 3.5 0 1 1-.707-.707l3.197-3.197l-3.197-3.197A3.5 3.5 0 1 1 9 6.5zm-1 0a2.5 2.5 0 1 0-5 0a2.5 2.5 0 0 0 5 0zM19.293 4H20v.707l-7.146 7.147l-.708-.707L19.293 4zM5.5 16a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5z" fill="#e11a28"></path></svg></a>

                                                            // <a title="cancel order" href="#" ><svg className="view_action_btn" viewBox="0 0 24 24"><path d="M18 19a3 3 0 0 1-3 3H8a3 3 0 0 1-3-3V7H4V4h4.5l1-1h4l1 1H19v3h-1v12zM6 7v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V7H6zm12-1V5h-4l-1-1h-3L9 5H5v1h13zM8 9h1v10H8V9zm6 0h1v10h-1V9z" fill="#e11a28"></path></svg></a>

                                                            // <a  target="_blank" title="track order" href="#"> <svg className="view_action_btn" viewBox="0 0 24 24"><path d="M5.5 14a2.5 2.5 0 0 1 2.45 2H15V6H4a2 2 0 0 0-2 2v8h1.05a2.5 2.5 0 0 1 2.45-2zm0 5a2.5 2.5 0 0 1-2.45-2H1V8a3 3 0 0 1 3-3h11a1 1 0 0 1 1 1v2h3l3 3.981V17h-2.05a2.5 2.5 0 0 1-4.9 0h-7.1a2.5 2.5 0 0 1-2.45 2zm0-4a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zm12-1a2.5 2.5 0 0 1 2.45 2H21v-3.684L20.762 12H16v2.5a2.49 2.49 0 0 1 1.5-.5zm0 1a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zM16 9v2h4.009L18.5 9H16z" fill="#e11a28"></path></svg> </a>
                                                            // <a target="_blank" title="location" href="#" hidden=""> <svg className="view_action_btn" viewBox="0 0 24 24"><path d="M11.5 7a2.5 2.5 0 1 1 0 5a2.5 2.5 0 0 1 0-5zm0 1a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zm-4.7 4.357l4.7 7.73l4.7-7.73a5.5 5.5 0 1 0-9.4 0zm10.254.52L11.5 22.012l-5.554-9.135a6.5 6.5 0 1 1 11.11 0h-.002z" fill="#e11a28"></path></svg></a></p>
                                                        }
                                                    </div>
                                                </div>



                                            </div>
                                        </div>

                                        <div class="fix_wrapper_h order_history_details" id="tracking_status">
                                            <div class="row main_location fix_location" id="click_btn">
                                                <div class="user_name_">
                                                    <h3>{lang.tracking_history} {this.state.allData.track_no}</h3>
                                                </div>
                                                <div id="fix_top">
                                                    <div class="w_10_px">
                                                        <ul>
                                                            <li>
                                                                <i class="fa fa-calendar"></i>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="w_90_px status_box">
                                                        <h3><span>{lang.date}</span> <b>{lang.status}</b></h3>
                                                        {
                                                            (this.state.history !== '' && this.state.history.length > 0) ?
                                                                this.state.history.map((value, index) => {
                                                                    var status_name = value.order_status ? value.order_status : '';

                                                                    lang_array.length > 0 && lang_array.map(trnas_val => {
                                                                        console.log(trnas_val)
                                                                        if (trnas_val.keyword == status_name) {

                                                                            status_name = trnas_val.translation;
                                                                        }
                                                                    })
                                                                    return (
                                                                        <p><span>{value.created_on}</span><b>{status_name}</b></p>
                                                                    );
                                                                })
                                                                :
                                                                ''
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                        }

                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}
export default PickupRequest;
