import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { api_url } from '../Config';
import swal from 'sweetalert';
import chat_avatar1 from '../assets/images/chat_avatar_01.jpg';
import chat_avatar2 from '../assets/images/chat_avatar_02.jpg';
import chat_avatar3 from '../assets/images/chat_avatar_03.jpg';
import chat_avatar4 from '../assets/images/chat_avatar_04.jpg';
import Footer from './Footer';
import CommonHeader from '../component/CommonHeader';
import database from "../firebaseDB";
import { ref, onValue, update, increment } from "@firebase/database";
import CustomerHeader from '../component/CustomerHeader';
import { sendFcmMessage } from '../utils/sendFcmMessage';
const chatRef = ref(database, 'chats');
const notificationRef = ref(database, 'notifications');

function generateRandomString(length) {
   const characters = '0123456789abcdefghijklmnopqrstuvwxyz';
   let result = '';
   for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
   }
   return result;
}
function validateCount(count) {

   if (count <= 9) {
      return count.toString();
   } else {
      return '9+';
   }
}

const ChatListItem = ({ data, onClick, active }) => {
   const { image, status, last_message_timestamp, name, unread_customer } = data;
   console.log(data);
   const className = "clearfix " + (active ? "active" : "")

   return (
      <li className={className} onClick={() => onClick(data)}>
         <Link to="#" >
            <img src={image} alt="" />
            <div className="about">
               <div className="status">
                  <i className={`fa fa-circle ${status ? ' online' : ' offline'}`}></i> {data.id}
               </div>
               <div className="date_box">
                  {new Date(last_message_timestamp * 1000 - 3 * 60 * 60 * 1000).toLocaleString('en-US', { timeZone: 'Asia/Baghdad' })}
               </div>
               <div className="name">{name}</div>
               {unread_customer ? <div className='total_mes_count' style={{ float: 'right' }}>{validateCount(unread_customer)}</div> : <React.Fragment></React.Fragment>}
            </div>
         </Link>
      </li>
   );
};

const ChatBox = ({ data, onChatSubmit, onBack }) => {
   console.log(data);
   const { chat_avatar, name, id, messages, rawId } = data;
   let messageList = Object.values(messages);
   // messageList.sort((message1, message2) => (new Date(message1.created_at)) - (new Date(message2.created_at)));

   messageList.sort((message1, message2) => ((message1.timestamp)) - ((message2.timestamp)));
   const [message, setMessage] = React.useState("");
   const [submitting, setSubmitting] = React.useState(false);
   const submitPressed = () => {
      setSubmitting(true);
      // console.log("Button clicked");
      let chatMasterId, userId;
      if (rawId) {
         chatMasterId = rawId.split('-')[0];
         userId = rawId.split('-')[1];
      } else {

      }

      const chatMessage = message;
      // onChatSubmit(orderId, orderNo, userId, messageText) 

      onChatSubmit(chatMasterId, rawId, userId, chatMessage).then(() => {
         setMessage("");
         // console.log("Success");
      }).catch(() => {

      }).finally(() => {
         setSubmitting(false);
      });
   }
   React.useEffect(() => {
      scrollToBottom();
   });
   let messagesEnd;
   const scrollToBottom = () => {
      if (messagesEnd) {
         messagesEnd.scrollIntoView({ behavior: "smooth" });

      }
   }
   return <React.Fragment>
      <div className={"chat chat_box_hide " + (data ? "active_chat" : "")}>
         <div className="chat-header clearfix">
            <div onClick={onBack} className="backBtn"><i className="fa fa-angle-left"></i></div>
            <img src={chat_avatar} alt="" />
            {name ? <div className="chat-about">
               <div className="chat-with">{name}</div>
               <div className="chat-num-messages">{messageList.length} messages</div>
            </div> : <div className="chat-about">
               <div className="chat-with">New Chat</div>
            </div>

            }
            <b>{id}</b>
         </div>

         <div className="chat-history">
            <ul>
               {messageList.filter(message => message.message).map((message) => {
                  if (message.sender == 0) {
                     return (<li>
                        <div className="message-data">
                           <span className="message-data-name"><i className="fa fa-circle online"></i></span>
                           <span className="message-data-time">{message.created_at}</span>
                        </div>
                        <div className="message my-message">
                           {message.message}
                        </div>
                     </li>)
                  } else {
                     return (
                        <li className="clearfix">
                           <div className={"message-data align-right"}>
                              <span className="message-data-time">{message.created_at}</span> &nbsp; &nbsp;
                              <span className="message-data-name"></span> <i className="fa fa-circle me"></i>
                           </div>
                           <div className="message other-message float-right">
                              {message.message}
                           </div>
                        </li>
                     )
                  }
               })}


               <div style={{ float: "left", clear: "both" }}
                  ref={(el) => { messagesEnd = el; }}>
               </div>
            </ul>
         </div>

         <div className="chat-message clearfix formbox">
            <textarea value={message} onChange={e => setMessage(e.target.value)} onKeyDown={(event) => {
               if (event.key === 'Enter' && !event.shiftKey) {
                  // 👇 Get input value
                  event.preventDefault();
                  submitPressed();
               }
            }} name="message-to-send" id="message-to-send" placeholder="Type your message" rows="3"></textarea>
            <button disabled={submitting}><i onClick={submitPressed} className={"fa " + (submitting ? "fa-spinner fa-spin" : "fa-send-o")}></i></button>
         </div>
      </div>
   </React.Fragment>;
}

//  export default ChatListItem;
class ChatList extends Component {
   constructor() {
      super();
      this.state = {
         redirectToReferrer: false,
         base_url: api_url.base_url,
         email: '',
         password: '',
         chatData: [],
         selectedChat: null,
         searchText: '',
      };
      this.save_record = this.save_record.bind(this);
      this.onChange = this.onChange.bind(this);
      this.login = this.login.bind(this);
      this.onChatSubmit = this.onChatSubmit.bind(this);
      this.backButtonPressed = this.backButtonPressed.bind(this);

   }
   componentDidMount() {
      this.routeChatId = this.props.match.params.orderid;
      this.routeRiderId = this.props.match.params.delivery_rider;
      console.log(`Route Chat ID: ${this.routeChatId}`);

      onValue(chatRef, (snapshot) => {
         const contacts = snapshot.val();
         let chatList = []
         let selectedChat;
         Object.entries(contacts).forEach(contactArray => {
            const contact = contactArray[1].chat_master;
            let id = contactArray[0];
            if (contact && contact.client_id == localStorage.getItem('customer_id')) {
               chatList.push({
                  image: contact.image,
                  status: this.lastSeenDifference(contact.last_seen),
                  date: contact.last_seen,
                  last_message_timestamp: contact.last_message_timestamp,
                  name: contact.name,
                  id: id.split('-')[0],
                  rawId: id,
                  unread_customer: contact.unread_customer,
                  messages: contactArray[1].messages,
               });
            }
         });
         // console.log(this.state.selectedChat);
         if (this.state.selectedChat === null) {
            // console.log(this.state.selectedChat);
            if (this.routeChatId) {
               selectedChat = (chatList.find((chatItem) => ((chatItem.id == this.routeChatId) && chatItem.rawId.includes('delivery_rider'))) ?? {
                  id: this.routeChatId,
                  messages: [],
               });
            } else {
               // TODO: default opened chat doesn't need for mobile devices
               selectedChat = {
                  messages: [],
               };
            }
         } else {
            selectedChat = ((chatList.find((chatItem) => ((chatItem.id == this.state.selectedChat.id) && chatItem.rawId.includes('delivery_rider')))) ?? {
               id: this.routeChatId,
               messages: [],
            });
            // debugger;

         }

         this.setState({
            chatData: chatList,
            selectedChat: selectedChat,
         });
      });
   }
   lastSeenDifference(dateStr) {
      const targetDate = new Date(dateStr);
      // Get the current date and time
      const currentDate = new Date();

      // Calculate the time difference in milliseconds
      const timeDifference = currentDate - targetDate;

      // Convert the time difference to minutes and hours
      const minutes = Math.floor(timeDifference / (1000 * 60));
      const hours = Math.floor(minutes / 60);

      // Format the result with a maximum of 3 characters
      let result;
      if (hours >= 1) {
         result = hours + "h";
         return false; // currently sends true or false
      } else {
         result = minutes + "m";
         return minutes < 10;

      }
      return (result); // remove above booleans it will send difference.
   }
   login() {
      if (this.state.email === '' || this.state.password === '') {
         alert('Email and Password required.')
      } else {
         let signupData = {
            email: this.state.email,
            'password': this.state.password
         }
         const addUrl = this.state.base_url + 'consignee_api.php?type=consignee_login';
         fetch(addUrl,
            {
               method: 'POST',
               headers:
               {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
               },
               body: JSON.stringify(signupData)
            })
            .then((response) => response.json()
               .then((result) => {
                  if (result.consignee_id > 0) {
                     localStorage.setItem('consignee_id', result.consignee_id);
                     this.save_record(result.consignee_id);
                  } else {
                     swal('Invalid Details.')
                  }
               }))
            .catch((error) => {

            });
      }


   }
   save_record() {
      let addData = {
         track_no: localStorage.getItem('track_no'),
         'consignee_id': localStorage.getItem('consignee_id')
      }
      const addUrl = this.state.base_url + 'consignee_api.php?type=save_record';
      fetch(addUrl,
         {

            method: 'POST',
            headers:
            {
               'Accept': 'application/json',
               'Content-Type': 'application/json',
            },
            body: JSON.stringify(addData)
         })
         .then((response) => response.json()
            .then((result) => {
               if (result.response === 1) {
                  this.setState({
                     redirectToReferrer: true,
                  })
               }
            }))
         .catch((error) => {

         });
   }
   onChange(e) {
      this.setState({ [e.target.name]: e.target.value });
   }
   filtered_chats() {
      const searchText = this.state.searchText.toLowerCase();
      let filteredChats = this.state.chatData;
      filteredChats = filteredChats.sort((a, b) => {
         return (b.last_message_timestamp - a.last_message_timestamp);
         // return (new Date(b.date) - new Date(a.date));
      });
      if (searchText === '') {

      } else {
         filteredChats = filteredChats.filter((chat_item) => chat_item.id === searchText || chat_item.date.toLowerCase().includes(searchText) || chat_item.name.toLowerCase().includes(searchText));
      }

      return filteredChats
   }
   async onChatSubmit(orderId, orderNo, userId, messageText) {
      console.log("onChatSubmit Clicked");
      const s = generateRandomString(5);
      const date = new Date().toLocaleString('en-US', { timeZone: 'Asia/Baghdad' });
      const currentDate = new Date();
      const iraqTimezoneOffset = 3 * 60 * 60 * 1000; // Offset in milliseconds (GMT+3)
      const formattedDate = (Date.parse(currentDate) + iraqTimezoneOffset) / 1000;

      // make unread count zero
      // make customer unread count 1
      if (orderId) {
         // add message in existing table
         const message = {
            [s]: {
               "created_at": date,
               "timestamp": formattedDate,
               "master_id": orderId,
               "message": messageText,
               "sender": "1",
               "unread": "0"
            }
         };
         const notification = {
            [userId]: {
               notify: {
                  message: messageText,
                  orderId: orderId //order id
               }
            }
         }
         const messagesRef = ref(database, 'chats/' + orderNo + '/messages');
         await update(messagesRef, message);
         await update(notificationRef, notification);
         update(ref(database, 'chats/' + orderNo + '/chat_master'), { unread_customer: 0, unread_rider: increment(1), last_message_timestamp: formattedDate, client_name: localStorage.getItem('customer_name'), }).then((res) => {
         })
         debugger;
         return sendFcmMessage({
            rider_id: userId,
            orderId: orderId,
            message: messageText
         });

      } else {
         // create new entry in firestore 
         console.log("Creating new entry in firestore");
         const orderIdU = this.routeChatId;
         const userIdu = this.routeRiderId;
         const orderNou = `${orderIdU}-${userIdu}-delivery_rider`; // track_id-delivery_id-delivery_rider
         let trackData = { "customer_id": localStorage.getItem('customer_id'), "track_no": this.routeChatId }
         const DetailUrl = 'https://portal.taslim.net/APIV2/customer_api.php?type=track_one_order';
         console.log(DetailUrl);
         fetch(DetailUrl, {
            method: 'POST',
            body: JSON.stringify(trackData),
         }).then(response => response.json()).then(res => {
            const notification = {
               [userIdu]: {
                  notify: {
                     message: messageText,
                     orderId: orderIdU //order id
                  }
               }
            }

            const message = {
               [s]: {
                  "created_at": date,
                  "timestamp": formattedDate,
                  "master_id": orderIdU,
                  "message": messageText,
                  "sender": "1",
               }
            };
            const currentDate = new Date();

            const chat_master = {
               client_id: localStorage.getItem('customer_id'),
               created_at: currentDate.toLocaleString(),
               image: "https://portal.taslim.net/img/download.jpg",
               last_seen: currentDate.toLocaleString(),
               client_name: localStorage.getItem('customer_name'),
               last_message_timestamp: formattedDate,
               name: res.orders.delivery_rider_name, // TODO: change
               orderId: orderIdU,
               phone: "",// we don't need phone No
               rider_id: userIdu,
               timestamp: currentDate.getTime(),
               unread_customer: 0,
               unread_rider: 1,
            };



            const row = {
               [orderNou]: {
                  chat_master,
                  messages: message
               }
            }
            const messagesRef = ref(database, 'chats/');
            update(messagesRef, row);
            update(notificationRef, notification);
            return sendFcmMessage({
               rider_id: userIdu,
               orderId: orderIdU,
               message: messageText,
            });
         });

      }

   }
   backButtonPressed() {
      this.setState({
         selectedChat: {
            id: null,
            messages: [],
         },
      })
   }
   render() {
      const chatItemClicked = (chatItem) => (() => {
         // TODO: show loader
         if (chatItem.unread_customer) {
            update(ref(database, 'chats/' + chatItem.rawId + '/chat_master'), { unread_customer: 0 }).then((res) => {
               this.setState({ selectedChat: chatItem });

            })

         } else {
            this.setState({ selectedChat: chatItem });

         }
         // TODO: make unread count to 0
      })

      const lang = JSON.parse(localStorage.getItem("lang"));
      if (this.state.redirectToReferrer) {
         return (<Redirect to='track-listing' />)
      }

      import('../assets/css/chatlist.css');
      return (

         <React.Fragment>
            <body id="page-name">
               <CustomerHeader />


               <div className="formbox dashboard_page chat_box" id="active_box">
                  <div className="popup_box">
                     <div className="clearfix">
                        <div className="people-list" id="people-list">
                           <div className="search">
                              <input type="text" placeholder="search" value={this.state.searchText} onChange={(val) => { this.setState({ searchText: val.target.value }) }} />
                              <i className="fa fa-search"></i>
                           </div>
                           <ul className="list">
                              {this.filtered_chats().map((chatItem, index) => (
                                 <ChatListItem active={this.state.selectedChat.rawId == chatItem.rawId} index={index} data={chatItem} onClick={chatItemClicked(chatItem)} />
                              ))}
                           </ul>
                        </div>
                        {this.state.selectedChat && this.state.selectedChat.id ?
                           <ChatBox data={this.state.selectedChat} onChatSubmit={this.onChatSubmit} onBack={this.backButtonPressed} />
                           : null}

                     </div>
                  </div>
               </div>


               <Footer />

            </body>
         </React.Fragment>
      )
   }
}

export default ChatList;
