import React,{useState, useEffect } from 'react'
const Header = () => {
    let lang_name = localStorage.getItem('lang_name');
    if (lang_name =='') {
       localStorage.setItem('lang',JSON.stringify(this.state.english));
       localStorage.setItem('lang_name','english');
    }
   const [logo, setlogo] = useState('');
   useEffect(() => {
    var api_rul_info = this.state.base_url+'api.php?type=get_site_info';
        fetch(api_rul_info)
        .then(res=>res.json())
        .then(result=>{
            if (result.response==1) {
                setlogo(result.site_logo);

            }
        })
  });
   const lang =JSON.parse(localStorage.getItem("lang"));
    return (
        <div className="row top_head co-one">
                <div className="col-sm-2 back_btn">
                    {
                //<Link to="/">
                //<svg width="30px" height="30px"  viewBox="0 0 24 24"><path d="M16 8.414l-4.5-4.5L4.414 11H6v8h3v-6h5v6h3v-8h1.586L17 9.414V6h-1v2.414zM2 12l9.5-9.5L15 6V5h3v4l3 3h-3v7.998h-5v-6h-3v6H5V12H2z" fill="#fff"/></svg>
                //</Link>
            }
            </div>
            <div className="col-sm-8 page_title">
                <h4>{lang.booking}</h4>
            </div>
            <div className="col-sm-2 right_logo">
            </div>
        </div>
    )
}

export default Header;
