import React, {Component} from 'react';
import { Redirect  } from 'react-router-dom';
import $ from 'jquery';
import {api_url} from '../Config';
import Footer from './Footer';
import loader from '../assets/images/770.gif';
import CommonHeader from '../component/CommonHeader';
class PickupRequest extends Component {
constructor(){
super();
this.state = {
redirectToReferrer: false,
track_no:'',
base_url:api_url.base_url,
allData:[],
historyData:[],
loading:true
// orderData:this.props.location.state.data,
};

this.addToListing = this.addToListing.bind(this);
this.checkLogin = this.checkLogin.bind(this);
}
componentDidUpdate()
{

}
componentDidMount(){


let trackData = {
            consignee_id:localStorage.getItem('consignee_id'),
            order_id:this.props.match.params.track_no
        }
    // console.log(trackData);
      const addUrl = this.state.base_url+'consignee_api.php?type=track_shipment';
        fetch(addUrl,
        {

            method: 'POST',
            headers:
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body:JSON.stringify(trackData)
        })
        .then((response) => response.json()
            .then((result) => {
                 // localStorage.setItem("data", JSON.stringify(result));
               this.setState({
                 allData:result.result,
                 historyData:result.history,
                 loading:false,
               })
        }))
        .catch((error) => {
            this.setState({loading:false})
        });

}
componentWillUnmount(){
localStorage.removeItem('data');
}
// componentWillMount() {
//  if(sessionStorage.getItem("userData")){
//      this.getUserData();
//  }
//  else{
//      this.setState({redirectToReferrer: true});
//  }
// }
// getUserData() {
//  JSON.parse(sessionStorage.getItem("userData"));
// }
addToListing(track_no)
{
    let addData = {
        track_no:track_no,
        'consignee_id':localStorage.getItem('consignee_id')
    }
    const addUrl = this.state.base_url+'consignee_api.php?type=save_record';
    fetch(addUrl,
    {

        method: 'POST',
        headers:
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body:JSON.stringify(addData)
    })
    .then((response) => response.json()
        .then((result) => {
            this.setState({
                redirectToReferrer:true,
              });
    }))
    .catch((error) => {

        });
}

checkLogin(track_no)
{
    this.setState({
        track_no:track_no
    })

}
render(){
 const lang =JSON.parse(localStorage.getItem("lang"));
if(this.state.track_no!==''){
    localStorage.setItem('track_no',this.state.track_no);
    return(<Redirect to='/login' />);
}
if(this.state.redirectToReferrer)
  {
    return(<Redirect to='/track-listing' />)
  }
return(
<React.Fragment>
<div className="main_body register_main track_shipment_page" id="desktop_view">
    <div className="login_screen">
        <CommonHeader />

    {
        this.state.loading?

        <img className="loader_onorder" src={loader} alt="Loading data" />
        :
        <form key={this.state.allData.track_no}>
        <div className="track_details_page" id="track_details">
            <div className="fix_wrapper_h sro_record">
                <div className="row main_location fix_location" id="click_btn">
                    <div className="user_name_">
                        <h3> {lang.tracking_result} {this.state.allData.track_no}

                        {localStorage.getItem('consignee_id')

                        ?

                        <button type="button" onClick={()=>this.addToListing(this.state.allData.track_no)} className="save_future">{lang.save}</button>
                        :

                        <button onClick={()=>this.checkLogin(this.state.allData.track_no)} type="button" className="save_future">{lang.save}</button>

                        }

                        </h3>
                    </div>
                    <div id="fix_top" className="shiping-consignee-bdr">
                        <div className="w_10_px ">
                            <ul>
                                <li><i className="fa fa-map-marker"></i></li>
                            </ul>
                        </div>
                        <div className="w_90_px track-result">
                            <h3>{lang.shipper_info}  </h3>
                            <p><b  style={{color:'#416baf'}}>{lang.shipper}:</b> {this.state.allData.sname} {this.state.allData.sbname && ' ( '+this.state.allData.sbname+' )'}</p>
                            <p><b  style={{color:'#416baf'}}>{lang.origin}:</b> {this.state.allData.origin}</p>
                        </div>
                    </div>
                    <div id="fix_top">
                        <div className="w_10_px">
                            <ul>
                                <li><i className="fa fa-user"></i></li>
                            </ul>
                        </div>
                        <div className="w_90_px track-result Consignee_box">
                            <h3>{lang.consigne_info}</h3>
                            <p><b  style={{color:'#416baf'}}>{lang.name}:</b> {this.state.allData.rname}</p>
                            <p><b  style={{color:'#416baf'}}>{lang.destination}:</b> {this.state.allData.destination}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fix_wrapper_h" id="tracking_status">
                <div className="row main_location fix_location" id="click_btn">
                    <div className="user_name_">
                        <h3>{lang.tracking_history} {this.state.allData.track_no}</h3>
                    </div>
                    <div id="fix_top">
                        <div className="w_10_px">
                            <ul>
                                <li><i className="fa fa-calendar"></i></li>
                            </ul>
                        </div>
                        <div className="w_90_px status_box">
                            <h3><span>{lang.date}</span> <b>l{lang.status}</b></h3>
                            {
                            this.state.historyData.map(item=>{
                            return(
                            <p key={item.id}><span>{item.created_on}   </span>  <b>{item.order_status}</b></p>
                            )
                            })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    }

</div>
</div>
<Footer />
</React.Fragment>
)
}
}
export default PickupRequest;
