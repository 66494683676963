import React, { Component } from "react";
import axios from "axios";
import Header from "./Header.js";
import swal from 'sweetalert';
import Footer from './Footer';
import CustomerHeader from '../component/CustomerHeader';
import { Redirect } from 'react-router-dom';
import { api_url } from '../Config.js';
import { Link } from 'react-router-dom';
import loader from '../assets/images/770.gif';
import { ref, onValue, update } from "@firebase/database";
import database from "../firebaseDB";

const contactRef = ref(database, 'chats');


class ScrollComponent extends Component {
    constructor() {
        super();
        this.state = {
            orders: [],
            loading: false,
            decimalValue: 0,
            redirectToReferrer: false,
            customer_id: localStorage.getItem('customer_id'),
            base_url: api_url.base_url,
            img_url: api_url.img_url,
            prefix: '',
            suffix: '',
            redirect: false,
            'no_record': false,
            'search': null,
            search_key: null
        };
        this.activeSearch = this.activeSearch.bind(this);
        this.checkKey = this.checkKey.bind(this);
        this.messagesCountData = {};
    }
    componentWillUnmount() {
        sessionStorage.removeItem('search_status');
    }
    componentDidMount() {
        this.getOrders();
        function validateCount(count) {
            if (count <= 5) {
                return count.toString();
            } else {
                return '5+';
            }
        }
        onValue(contactRef, (snapshot) => {
            const contacts = snapshot.val();
            let chatList = {};
            Object.entries(contacts).forEach(contactArray => {
                const contact = contactArray[1].chat_master;
                let id = contactArray[0];
                if (contact && id.includes('delivery_rider')) {
                    chatList[id.split('-')[0]] = {
                        image: contact.image,
                        name: contact.name,
                        id: id.split('-')[0],
                        rawId: id,
                        messageCount: validateCount(contact.unread_customer ?? 0),
                    }
                }
            });
            this.messagesCountData = chatList;
            console.log(this.messagesCountData);
            this.setState({});
        });
    }

    getOrders(search = null) {
        this.setState({ loading: true })
        if (localStorage.getItem('customer_id') > 0) {

            var status_get = 'all';
            if (sessionStorage.getItem('search_status')) {
                status_get = sessionStorage.getItem('search_status');
            }
            const dashData = {
                customer_id: this.state.customer_id,
                search_status: status_get,
                search: search
            }
            const getDashOrders = this.state.base_url + 'customer_api.php?type=getDashOrders';
            fetch(getDashOrders,
                {
                    method: 'POST',
                    headers:
                    {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(dashData)
                })
                .then((response) => response.json()
                    .then((result) => {
                        if (result.response == 1) {
                            this.setState({
                                orders: result.orders,
                                loading: false
                            })
                        } else {
                            this.setState({
                                orders: [],
                                loading: false
                            })
                        }
                    }))
                .catch((error) => {
                    this.setState({ loading: false })
                });
        }
    }

    componentWillMount() {
        if (localStorage.getItem("customer_id")) {
            this.getUserData();
        }
        else {
            this.setState({ redirectToReferrer: true });
        }
    }
    getUserData() {
        JSON.parse(localStorage.getItem("customer_id"));

    }
    editData(id) {
        if (id !== null) {
            localStorage.setItem('orderId', id)
            if (localStorage.getItem('orderId') !== null) {
                this.setState({ redirect: true })
            }
        }
    }

    activeSearch(e) {
        this.setState({ search_key: e.target.value })

    }
    checkKey(e) {
        if (e.key == 'Enter') {
            this.getOrders(this.state.search_key)
        }
    }

    render() {
        const lang = JSON.parse(localStorage.getItem("lang"));
        const lang_array = JSON.parse(localStorage.getItem("trnas_array")) ? JSON.parse(localStorage.getItem("trnas_array")) : [];
        const { orders } = this.state;
        // Additional css
        const loadingCSS = {
            height: "100px",
            margin: "0px 30px"
        };

        // To change the loading icon behavior
        const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
        if (this.state.redirectToReferrer) {
            return (<Redirect to={`/from-me-shipment`} />)
        }
        const GetMessageCount = ({value})=> {
            let count = (this.messagesCountData[value.track_no] ?? {}).messageCount;
            if(count === undefined || count == 0){
               return ""; 
            }
            return <span>{ count }</span>;
        }
        return (
            <React.Fragment>
                <div id="page-name">
                    <div className="booking_values main_body register_main track_shipment_page" id="desktop_view">
                        <div className="login_screen">
                            <CustomerHeader title={lang.orders} />
                            <div className="search_order_list">
                                <i className="fa fa-search"></i>
                                <input type="text" placeholder="Search here." onChange={this.activeSearch} onKeyPress={this.checkKey} />
                            </div>
                            <div className="track_details_page" id="desktop_orders">
                                {(this.state.orders !== '' && this.state.orders.length > 0) ?
                                    this.state.orders.map((value, index) => {
                                        var status_name = value.status ? value.status : '';

                                        lang_array.length > 0 && lang_array.map(trnas_val => {
                                            if (trnas_val.keyword == status_name) {

                                                status_name = trnas_val.translation;
                                            }
                                        })
                                        return (
                                            <Link to={`order-detail/${value.track_no}`} key={index}>
                                                <div className="order_info_details clearfix">
                                                    <div className="inner_info">
                                                        <p className="odr-no">#{value.track_no}</p>
                                                        <p>{value.order_time}</p>

                                                        <div className="inner_info location_to">
                                                            <p>{value.origin}  <h4>{value.destination}</h4></p>
                                                            <div className="from_to"><span></span></div>
                                                        </div>
                                                    </div>
                                                    <div className="inner_info right_box">
                                                        <p style={{ fontSize: '17px' }}>{value.rname}</p>
                                                        <p>{value.rphone}</p>
                                                        <p><b className="cod_blue"><i className="fa fa-lightbulb-o"></i> {value.service_code} - <i className="fa fa-balance-scale"></i> {value.weight} Kg</b>
                                                        </p>
                                                        <p><b><i className="fa fa-credit-card"></i> {value.net_amount}</b>
                                                        </p>
                                                        <p><b className="checked_mark"><i className="fa fa-check"></i> {status_name}</b>
                                                        </p>
                                                        {
                                                            value.phone ? <p><b className="checked_mark"><i className="            fa fa-phone"></i> {value.phone}</b>
                                                            </p>
                                                                : ''
                                                        }

                                                    </div>
                                                    {value.delivery_rider ? <Link to={`/chatlist/${value.track_no}/${value.delivery_rider}`} className="unread_chat_initiation" >
                                                        <i className="fa fa-comments"></i>
                                                        <GetMessageCount  value={value}/>
                                                    </Link> : ""}
                                                </div>

                                            </Link>
                                        )
                                    }
                                    )
                                    :

                                    ''
                                }

                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </React.Fragment>

        );
    }
}

export default ScrollComponent;
