import React, {Component} from 'react';
import { Redirect} from 'react-router-dom';
class ContactUs extends Component {
    constructor(){
    super();
    this.state = {
        redirectToReferrer: false,

    };


}
componentDidMount(){
  localStorage.clear();
  this.setState({
    redirectToReferrer:true
  })
}

render(){
if(this.state.redirectToReferrer)
  {
    return(<Redirect to='from-me-shipment' />)
  }
return(
<React.Fragment>
</React.Fragment>
    )
}
}
export default ContactUs;
