import React from "react";
import { Link, Redirect } from 'react-router-dom';
import {english} from '../component/English';
import {kurdish} from '../component/Kurdish';
import {arabic} from '../component/Arabic';

const LANGUAGES = {
    english,
    kurdish,
    arabic,
};

class CustomerDashboard extends React.Component {

    render() {

        
        // const customerId = this.props.match.params.customer_id;
        const {lang, customer_id} = this.props.match.params;
        if(lang){
            localStorage.setItem('lang_name',lang);
            localStorage.setItem('lang', JSON.stringify(LANGUAGES[lang]));
        }
        else{
            localStorage.setItem('lang_name','english');
            localStorage.setItem('lang', JSON.stringify(LANGUAGES['english']));

        }
        localStorage.setItem('customer_id', customer_id);
        // window.location.href = '#/dashboard';
            return (<Redirect to='/dashboard' />)
    }
}

export default CustomerDashboard;